var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sms-message-actions" }, [
    _c("div", { staticClass: "sms-message-actions-dropdown" }, [
      _c(
        "button",
        {
          staticClass: "sms-message-actions__btn",
          attrs: {
            type: "button",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _c("svg-icon", {
            attrs: { name: "ic-more-opaque", width: "16", height: "16" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "sms-message-actions-dropdown-menu",
          style: {
            display: _vm.isOpen ? "flex" : "none",
            left: _vm.isRtl ? 0 : "auto",
            right: _vm.isRtl ? "auto" : 0,
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "sms-message-actions-dropdown-menu-item",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.editSmsMessage()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("edit")) + "\n      ")]
          ),
          _c(
            "button",
            {
              staticClass: "sms-message-actions-dropdown-menu-item",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.deleteSmsMessage()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("delete")) + "\n      ")]
          ),
          _c(
            "button",
            {
              staticClass: "sms-message-actions-dropdown-menu-item",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.duplicateSmsMessage()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("duplicate")) + "\n      ")]
          ),
          _c(
            "button",
            {
              staticClass: "sms-message-actions-dropdown-menu-item",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.pauseSmsMessage()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("pause")) + "\n      ")]
          ),
          _c(
            "button",
            {
              staticClass: "sms-message-actions-dropdown-menu-item",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.activateSmsMessage()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("activate")) + "\n      ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }